<template>
    <TransitionsBounce>
        <div v-if="active" class="p-4 my-3 shadow rounded border flex flex-row gap-2 items-center transition-all duration-500 ease-out delay-75 whitespace-pre-wrap" v-bind:class="{
            'bg-orange-200 border-orange-400 dark:bg-orange-700 dark:border-orange-500': type === 'warning',
            'bg-primary text-white border-blue-400 dark:border-blue-500': type === 'info',
            'bg-red-400 text-white border-red-700 dark:bg-red-900 dark:border-red-600': type === 'error',
            'bg-green-500 border-green-400 dark:bg-green-900 dark:border-green-600': type === 'success',
        }">
            <slot v-if="!noIcon" name="icon">
                <ElementsIcon :icon="getIcon(type)" class="shrink-0" />
            </slot>
            <slot />
        </div>
    </TransitionsBounce>
</template>
<script setup>
defineProps({
    type: { type: String, default: 'warning' },
    active: { type: Boolean, default: false },
    noIcon: { type: Boolean, default: false },
})

const getIcon = (type) => {
    switch (type) {
        case 'warning':
            return 'croissant'
        case 'info':
            return 'messageBubble'
        case 'error':
            return 'cancel'
        case 'success':
            return 'checkmark'
    }
}
</script>